import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import * as styles from './navbar.module.scss';

export const Navbar = () => {
  const menus = useMemo<{ label: string; link: string }[]>(
    () => [
      { label: 'Vos besoins', link: '/#needSection' },
      { label: 'La RH', link: '/#whySection' },
      { label: 'Mon expertise', link: '/#activitySection' },
      { label: 'Ma bio', link: '/#bioSection' },
      { label: 'Contact', link: '/contact' },
    ],
    []
  );
  return (
    <ul className={styles.wrapper}>
      {menus.map((link) => (
        <li key={`navbar-menu-link-${link.label}`}>
          <Link to={link?.link}>{link.label}</Link>
        </li>
      ))}
    </ul>
  );
};
