import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { LogoQuery } from '../../types/graphql-types';
import * as styles from './logo.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const Logo = () => {
  const { sanityParams, allSanityImageAsset } = useStaticQuery<LogoQuery>(QUERY);
  if (!sanityParams?.entreprise) return null;
  const [nouat, conseil] = sanityParams.entreprise.split(' ');
  const image = getImage(allSanityImageAsset?.nodes?.[0]?.gatsbyImageData);
  if (!image) return null;
  return <GatsbyImage image={image} className={styles.image} alt="" />;
};

export const QUERY = graphql`
  query Logo {
    sanityParams {
      entreprise
    }
    allSanityImageAsset(filter: { width: { eq: 500 } }) {
      totalCount
      nodes {
        gatsbyImageData
      }
    }
  }
`;
