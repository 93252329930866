import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from '../components/header/Header';
import { Footer } from '../components/footer/Footer';
import * as styles from './layout.module.scss';

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  useEffect(() => {
    const scrollbarWidth =
      typeof window !== `undefined` ? window.innerWidth - document.body.clientWidth : 0;
    document.body.style.setProperty('--viewportWidth', `calc(100vw - ${scrollbarWidth}px)`);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};
