import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Logo } from '../logo/Logo';
import { FooterQuery } from '../../types/graphql-types';
import * as styles from './footer.module.scss';

export const Footer = () => {
  const { sanityParams } = useStaticQuery<FooterQuery>(QUERY);
  return (
    <div className={styles.wrapper}>
      <Link className={styles.linkHome} to="/">
        <Logo />
      </Link>
      <Link className={styles.link} to={sanityParams?.linkedin ?? '/'}>
        <img
          src="https://logos-marques.com/wp-content/uploads/2021/03/Linkedin-logo.png"
          alt="logo linkedin"
        />
      </Link>
    </div>
  );
};

const QUERY = graphql`
  query Footer {
    sanityParams {
      linkedin
    }
  }
`;
