import React from 'react';
import { Link } from 'gatsby';
import { Logo } from '../logo/Logo';
import { Navbar } from '../navbar/Navbar';
import * as styles from './header.module.scss';

export const Header = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Link className={styles.linkHome} to="/">
          <Logo />
        </Link>
      </div>
      <div className={styles.separator} />
      <Navbar />
      <div className={styles.separator} />
    </div>
  );
};
