import './src/styles/globals.scss';
import React from 'react';
import { Layout } from './src/layout/Layout';
import { ThemeContextProvider } from './src/hooks/context/useThemeContext';

export const wrapPageElement = ({ element }) => (
  <ThemeContextProvider>
    <Layout>{element}</Layout>
  </ThemeContextProvider>
);
