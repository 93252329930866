import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useContext, createContext, useMemo } from 'react';

export enum EnumTheme {
  light = 'light',
  dark = 'dark',
}

type IPageContext = {
  theme: EnumTheme;
  setTheme: React.Dispatch<React.SetStateAction<EnumTheme>>;
};

const PageContext = createContext<IPageContext | null>(null);

export const ThemeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [theme, setTheme] = useState(EnumTheme.light);

  const value = useMemo(() => ({ theme, setTheme }), [theme]);

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);

  return pageContext;
};
